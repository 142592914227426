<template>
  <form
    class="dropdown d-inline"
    @submit.prevent
  >
    <material-button
      color="secondary"
      variant="outline"
      class="dropdown-toggle"
      :class="{
        show: open,
      }"
      :aria-expanded="open"
      @click="open = !open"
    >
      {{ name }}
    </material-button>

    <ul
      class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
      :class="{
        show: open,
      }"
    >
      <li
        v-for="[optionValue, label] in options"
        :key="label"
        class="dropdown-item border-radius-md"
      >
        <material-checkbox
          :id="`${name}-${label}`"
          v-model="value"
          :name="name"
          :value="optionValue"
        >
          {{ label }}
        </material-checkbox>
      </li>
    </ul>
  </form>
</template>

<script>
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialCheckbox from '@/components/MaterialCheckbox.vue';

export default {
  name: 'CheckboxFilter',

  components: {
    MaterialButton,
    MaterialCheckbox,
  },

  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Map,
      default: new Map(),
    },
  },

  emits: [
    'update:modelValue',
  ],

  data: () => ({
    open: false,
  }),

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
