<template>
  <div class="user-card card">
    <div class="p-3 card-body">
      <div class="d-flex mt-n2">
        <div
          class="mt-n4 overflow-hidden flex-shrink-0"
        >
          <router-link
            :to="{
              name: 'edit-app-user',
              params: {
                id,
              }
            }"
            class="user-card__link stretched-link w-100 h-100"
          >
            <profile-picture 
              :width-and-height-in-pixels="75"
              :background-image-url="image"
            />
          </router-link>
        </div>
        <div class="my-auto ms-3">
          <h6 class="mb-0">{{ name }}</h6>
          <p class="text-xs mb-0">
            {{ club }}
          </p>

          <div
            class="d-flex gap-2 mt-2"
          >
            <material-badge
              v-if="isAdmin"
              size="sm"
              color="warning"
            >
              Admin
            </material-badge>
            
            <material-badge
              v-if="boardMemberFunction"
              size="sm"
              color="secondary"
            >
              {{ boardMemberFunction }}
            </material-badge>

            <material-badge
              v-if="isActivated"
              size="sm"
              color="success"
            >
              Actief
            </material-badge>
          </div>
        </div>
        <div class="ms-auto">
          <dropdown-menu
            button-padding-class="ps-4"
            :items="dropdownMenuItems"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { sendInvitation, getInviteLink } from '@/api/providers/users'
import { createYesNoSweetAlert } from '@/helpers/sweetalert'
import { remove } from '@/api/providers/users';
import DropdownMenu from '@/components/UI/DropdownMenu.vue';
import MaterialBadge from '@/components/MaterialBadge.vue';
import ProfilePicture from '../UI/ProfilePicture.vue';

export default {
  name: 'UserCard',

  components: {
    DropdownMenu,
    MaterialBadge,
    ProfilePicture
  },

  props: {
    boardMemberFunction: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      required: true,
    },
    image: {
      type: String,
      default: "",
    },
    isActivated: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    profession: {
      type: String,
      default: '',
    },
    companyName: {
      type: String,
      default: '',
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    clubs: { 
      type: Array,
      default: () => []
    }
  },

  emits: [
    'remove',
  ],

  computed: {
    club() {
      if(this.clubs.length < 1) return "";
      if(this.clubs[0].functions.length < 1) return this.clubs[0].clubName;
      return `${this.clubs[0].clubName} / ${this.clubs[0].functions[0]}`
    },

    dropdownMenuItems() {
      let items = [
        {
          handler: this.edit,
          name: 'Bewerken',
        }
      ];

      if(!this.isActivated) {
        items.push(
          {
            handler: this.sendAppInvite,
            name: 'Verstuur app-uitnodiging',
          },
          {
            handler: this.fetchAndCopyInviteUrlToClipboard,
            name: 'Uitnodigingslink kopiëren'
          }
        );
      }

      items.push({
        handler: this.remove,
        name: 'Verwijderen',
      });

      return items;
    }
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    edit() {
      this.$router.push({
        name: 'edit-app-user',
        params: {
          id: this.id
        },
      });
    },

    remove() {
      createYesNoSweetAlert(
        `Weet u zeker dat u de gebruiker <b>${this.name}</b> wilt verwijderen?`,
        () => {
          remove(this.id, this.name)
            .then(() => {
              this.addNotification({
                description: `${this.name} is verwijderd.`,
                icon: {
                  component: 'person_remove',
                },
              });
              this.$emit('remove');
            })
            .catch((error) => error.default());
        }
      );
    },

    sendAppInvite() {
      sendInvitation(this.id)
        .then(() => {
          this.addNotification({
            description: `Er is een app-uitnodiging verzonden naar ${this.name}.`,
            icon: {
              component: 'mark_email_read',
            },
          });
        })
        .catch((error) => error.default());
    },

    async fetchAndCopyInviteUrlToClipboard() {
      if (typeof window.ClipboardItem && navigator.clipboard.write) {
        const item = new window.ClipboardItem({
          'text/plain': getInviteLink(this.id).then(result => {
            if(!result) return new Promise(resolve => resolve(new Blob['']()));
            return new Promise(resolve => resolve(new Blob([result])));
          })
        });
        navigator.clipboard.write([item]).then(() => 
          this.addNotification({
              description: "Link gekopieerd naar het klembord",
              icon: {
                component: 'content_copy',
              },
          })
        );

        return;
      }

      await this.copyAppInviteLink();
    },

    async copyAppInviteLink() {
      try {
        var inviteLink = await getInviteLink(this.id);
        if(
          inviteLink == null || 
          inviteLink === ""
        ) {
          throw new Error('Er is een fout opgetreden bij het ophalen van de link');
        }
        
        await navigator.clipboard.writeText(inviteLink);

        this.addNotification({
            description: "Link gekopïeerd naar het klembord",
            icon: {
              component: 'content_copy',
            },
        });
      }
      catch(error) {
        this.addNotification({
            description: error,
            icon: {
              component: 'error',
            },
        });
      }
    }
  },
};
</script>

<style scoped lang="scss">
.user-card {
  height: 100%;

  &__link {
    display: grid;
    place-content: center;
  }
}
</style>
