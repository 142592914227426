export default function initializeSearchBarFocusEvent(
    focusEventHook = () => {},
    customQuerySelector = undefined
)
{
    const searchBar = document.querySelector(customQuerySelector ?? 'input[type=search]');

    if (searchBar !== undefined) {
        window.addEventListener("keydown", (e) => {
            if (e.code === 'F3' || ((e.ctrlKey || e.metaKey) && e.code === 'KeyF')) { 
              e.preventDefault();

              focusEventHook();
              searchBar.focus()
            }
          }
        )
    }
}
